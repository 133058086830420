
import { decode } from '@googlemaps/polyline-codec'
import MapsAndFlags from '@/assets/maps-and-flags.png'
import Circle from '@/assets/CircleWhite.png'
import BlackDot from '@/assets/Black-dot.png'
import L from 'leaflet'
import axios from '@/service/axios'
import Logo from '@/assets/imgs/cs_logo_black_256.png'
const state = {
  mapData: {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    zoom: 10,
    center: [53.554357, 9.996443],
    bounds: null,
    routes: [],
    markerData: [],
    stations: [],
    pois: []
  },
  travelTime: null,
  img: Logo
}
const getters = {
  getPOIData: (state) => index => state.mapData.pois[index],
  getRouteData: (state) => index => state.mapData.routes[index],
  mapData: (state) => state.mapData,
  getRoutes: (state) => state.mapData.routes,
  getPOIs: (state) => state.mapData.pois,
  getLogo: (state) => state.img
}
const actions = {
  async fetchMapData({ commit, dispatch }, token) {
    await axios.get(`/maps/${token}`)
      .then((res) => {
        dispatch('handleResult', res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  handleResult({ dispatch, commit }, apiResult) {
    if (apiResult.pois.length > 0) {
      dispatch('fillLocalPoisArray', apiResult)
    }
    if (apiResult.routes.length > 0) {
      dispatch('decodePloyLine', apiResult)
    }
    dispatch('calculateMapCenter', apiResult)
  },
  fillLocalPoisArray({ dispatch, state }, data) {
    data.pois.forEach(poi => {
      poi.show = true
      poi.icon = MapsAndFlags
      poi.iconSize = [25, 25]
      state.mapData.pois.push(poi)
    })
  },

  decodePloyLine({ dispatch }, data) {
    for (let index = 0; index < data.routes.length; index++) {
      data.routes[index].route = decode(
        data.routes[index].route
      )
    }
    dispatch('setStationIcon', data)
  },
  setStationIcon({ dispatch }, data) {
    data.routes.forEach(route => {
      for (let index = 0; index < route.stations.length; index++) {
        if (index + 2 > route.stations.length) {
          route.stations[index].icon = BlackDot
        } else {
          route.stations[index].icon = Circle
        }
        route.stations[index].iconSize = [20, 20]
      }
    })
    dispatch('fillLocalArrayStationsAndPois', data)
  },
  fillLocalArrayStationsAndPois({ state, dispatch }, data) {
    data.routes.forEach(async route => {
      for (let index = 0; index < route.stations.length; index++) {
        route.stations[index].travel_time = await dispatch('formatTime', route.stations[index])
        route.stations[index].distance = await dispatch('formatDistance', route.stations[index])
        state.mapData.stations.push(route.stations[index])
      }
    })
    dispatch('addShowAttribute', data)
  },
  addShowAttribute({ state, commit, dispatch }, data) {
    data.routes.forEach(route => {
      route.show = true
    })
    dispatch('calculateMapCenter', data)
  },
  calculateMapCenter({ state, dispatch }, payload) {
    const corner1 = L.latLng(payload.bounds.minLatitude, payload.bounds.minLongitude)
    const corner2 = L.latLng(payload.bounds.maxLatitude, payload.bounds.maxLongitude)
    state.mapData.bounds = L.latLngBounds(corner1, corner2)
    const center = state.mapData.bounds.getCenter()
    state.mapData.center = [center.lat, center.lng]
    dispatch('getFormattedTimeAndDistance', payload)
  },
  getFormattedTimeAndDistance({ commit, dispatch }, payload) {
    payload.routes.forEach(async(route) => {
      route.travel_time = await dispatch('formatTime', route)
      route.distance = await dispatch('formatDistance', route)
    })
    dispatch('calculateRouteCenter', payload)
  },
  formatTime({ dispatch }, payload) {
    const hours = Math.floor(payload.travel_time / 3600)
    const remainingSeconds = payload.travel_time % 3600
    const minutes = Math.floor(remainingSeconds / 60)
    if (hours === 0) {
      return `${minutes} min`
    } else {
      return `${hours} h ${minutes} min`
    }
  },
  formatDistance({ dispatch }, payload) {
    const kilometers = Math.round(payload.distance / 1000)
    return `${kilometers} km`
  },
  calculateRouteCenter({ commit, dispatch }, payload) {
    payload.routes.forEach(async(route) => {
      const centerLatitude = await dispatch('getLatitudeCenter', route.stations)
      const centerLongitude = await dispatch('getLongitudeCenter', route.stations)
      const routeCenter = {
        location: {
          latitude: centerLatitude,
          longitude: centerLongitude
        }
      }
      route.center = routeCenter
      commit('setMapData', payload)
    })
  },
  getLatitudeCenter({ dispatch }, stations) {
    const sortedLatitudes = stations.map(station => station.location.latitude).sort((a, b) => a - b)
    const centerLatitudes = (sortedLatitudes[sortedLatitudes.length - 1] + sortedLatitudes[0]) / 2
    return centerLatitudes
  },
  getLongitudeCenter({ dispatch }, stations) {
    const sortedLongitudes = stations.map(station => station.location.longitude).sort((a, b) => a - b)
    const centerLongitudes = (sortedLongitudes[sortedLongitudes.length - 1] + sortedLongitudes[0]) / 2
    return centerLongitudes
  }
}
const mutations = {
  setMapData(state, payload) {
    state.mapData.routes = payload.routes
  },
  setMapCenter(state, payload) {
    state.mapData.center = [
      payload.location.latitude,
      payload.location.longitude
    ]
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
